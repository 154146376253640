import 'bootstrap/dist/css/bootstrap.min.css';
import Routing from "./components/Routing";



function App() {
  return (
    <>
      <Routing />
    </>
  );
}

export default App;